import { Controller } from "react-hook-form";

import StringSingleSelectCombobox, {
  type StringSingleSelectComboboxProps,
} from "../../../components/Combobox/StringSingleSelectCombobox.js";
import type { LoadOptionMapFn, OptionMap } from "../../../hooks/useOptionMapLoader.js";
import type CommonFieldOptions from "../../models/CommonFieldOptions.js";
import HiddenFormInput from "./HiddenFormInput.js";
import type { InputWrapperProps } from "./InputWrapper.js";
import InputWrapper from "./InputWrapper.js";

interface OptionMapperProps {
  initialOptionMap?: OptionMap;
  loadOptionMap?: LoadOptionMapFn;
}

type SelectInputProps = CommonFieldOptions &
  Omit<StringSingleSelectComboboxProps, "onChange" | "selectedOption" | "value"> &
  Omit<InputWrapperProps, "label"> &
  OptionMapperProps & {
    name: string;
    defaultValue?: string;
    label?: string;
  };

const SelectInput = ({ name, defaultValue, ...props }: SelectInputProps) => {
  return (
    <InputWrapper {...props} htmlFor={name}>
      <Controller
        name={name}
        defaultValue={defaultValue}
        disabled={props.disabled}
        render={({ field }) => {
          return (
            <>
              <HiddenFormInput name={name} value={field.value ?? ""} type="text" />
              <StringSingleSelectCombobox
                {...props}
                value={field.value}
                placeholder={props.placeholder ?? `Select ${props.label}`}
                onChange={(value) => {
                  field.onChange(value);
                }}
              />
            </>
          );
        }}
      />
    </InputWrapper>
  );
};

export default SelectInput;
